<template>
  <div class="blue-skin">
    <div id="main-wrapper">
      <app-header />

      <div class="clearfix"></div>

      <section
        class="image-cover hero-banner py-5"
        style="background: #eff6ff url(assets/img/banner-2.jpg) no-repeat"
      >
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-6">
              <div class="card">
                <div class="card-body" v-if="! success">
                  <h3 class="card-header-title text-center">Forgot your password?</h3>
                  <p class="mb-4 text-tiny text-muted">Enter your email below to recover your account.</p>
                  <div class="alert alert-danger" v-if="has_error">
                    Invalid Email
                  </div>
                  <div class="login-form">
                    <form @submit.prevent="submit">
                      <div class="form-group">
                        <label>Email</label>
                        <div class="input-with-icon">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Email"
                            v-model="user.email"
                          />
                          <i class="ti-user"></i>
                        </div>
                      </div>

                      <div class="form-group text-center">
                        <button
                          type="submit"
                          class="btn btn-md btn-theme-light-2 rounded"
                          v-if="!is_loading"
                        >
                          Submit
                        </button>
                        <a
                          href="#"
                          @click.prevent=""
                          v-if="is_loading"
                          class="btn btn-md btn-theme-light-2 rounded"
                          ><i class="lni lni-spinner"></i> Sending Recovery Email</a
                        >
                      </div>
                    </form>
                  </div>
                  <div class="text-center" v-if="!is_loading">
                    <p class="mt-4">
                      <router-link :to="{ name: 'login' }" class="link">Back to Login</router-link>
                    </p>
                  </div>
                </div>
                <div class="card-body" v-if="success">
                  <div class="alert alert-success">We have sent an email to {{ user.email }}. Click on the link sent to recover your account.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <app-footer />
    </div>
  </div>
</template>

<script>
import AppHeader from "../components/AppHeader.vue";
import AppFooter from "../components/AppFooter.vue";

export default {
  components: {
    AppHeader,
    AppFooter
  },

  beforeMount() {
    if(this.$auth.hasToken()) {
      window.location.href = this.$router.resolve({ name: 'redirect' }).href
    }
  },

  data() {
    return {
      user: {},
      has_error: false,
      is_loading: false,
      success: false
    };
  },

  methods: {
    submit() {
      this.has_error = false;
      this.is_loading = true;
      this.success = false

      this.$axios
        .post("/api/v1/reset-password", this.user)
        .then(() => {
          this.is_loading = false
          this.success = true
        })
        .catch(() => {
          this.has_error = true;
          this.is_loading = false;
        });
    },
  },
};
</script>
